import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminDcHCalendarComponent } from './components/admin/dch-calendar/dch-calendar.component';
import { AdminFrontPageNewsComponent } from './components/admin/front-page-news/front-page-news.component';
import { AdminImportKonkurrencerComponent } from './components/admin/import-konkurrencer/import-konkurrencer.component';
import { AdminKonkurrencerComponent } from './components/admin/konkurrencer/konkurrencer.component';
import { AdminLiveKonkurrencerComponent } from './components/admin/live-konkurrencer/live-konkurrencer.component';
import { AdminLogsComponent } from './components/admin/logs/logs.component';
import { AdminStamDataComponent } from './components/admin/stam-data/stam-data.component';
import { AgilityDmCandidatesComponent } from './components/agility/agility-dm-candidates/agility-dm-candidates.component';
import { AgilityDogOfTheYearComponent } from './components/agility/agility-dog-of-the-year/agility-dog-of-the-year.component';
import { AgilityKredsOverViewComponent } from './components/agility/agility-kreds-overview/agility-kreds-overview.component';
import { AgilityLiveResultaterComponent } from './components/agility/agility-live-resultater/agility-live-resultater.component';
import { AgilityResultaterComponent } from './components/agility/agility-resultater/agility-resultater.component';
import { AuthGuard } from './services/authorizationCheck';
import { BeregnerProgrammerComponent } from './components/beregner-programmer/beregner-programmer.component';
import { BrugsIgpFhDmCandidatesComponent } from './components/brugs/brugs-igpfh-candidates/brugs-igpfh-candidates.component';
import { BrugsIpg3DmCandidatesComponent } from './components/brugs/brugs-ipg3dm-candidates/brugs-ipg3dm-candidates.component';
import { BrugsKredsOverViewComponent } from './components/brugs/brugs-kreds-overview/brugs-kreds-overview.component';
import { BrugsLiveResultaterComponent } from './components/brugs/brugs-live-resultater/brugs-live-resultater.component';
import { BrugsResultaterComponent } from './components/brugs/brugs-resultater/brugs-resultater.component';
import { EkvipageResultsComponent } from './components/ekvipage-results/ekvipage-results.component';
import { HomeComponent } from './components/home/home.component';
import { HoopersDmCandidatesComponent } from './components/hoopers/hoopers-dm-candidates/hoopers-dm-candidates.component';
import { HoopersKredsOverViewComponent } from './components/hoopers/hoopers-kreds-overview/hoopers-kreds-overview.component';
import { HoopersLiveResultaterComponent } from './components/hoopers/hoopers-live-resultater/hoopers-live-resultater.component';
import { HoopersResultaterComponent } from './components/hoopers/hoopers-resultater/hoopers-resultater.component';
import { LoginComponent } from './components/login/login.component';
import { LydighedDmCandidatesComponent } from './components/lydighed/lydighed-dm-candidates/lydighed-dm-candidates.component';
import { LydighedETurCandidatesComponent } from './components/lydighed/lydighed-etur-candidates/lydighed-etur-candidates.component';
import { LydighedETurResultsComponent } from './components/lydighed/lydighed-etur-results/lydighed-etur-results.component';
import { LydighedKredsOverViewComponent } from './components/lydighed/lydighed-kreds-overview/lydighed-kreds-overview.component';
import { LydighedLiveResultaterComponent } from './components/lydighed/lydighed-live-resultater/lydighed-live-resultater.component';
import { LydighedOprykkereComponent } from './components/lydighed/lydighed-oprykkere/lydighed-oprykkere.component';
import { LydighedResultaterComponent } from './components/lydighed/lydighed-resultater/lydighed-resultater.component';
import { NordiskDmCandidatesComponent } from './components/nordisk/nordisk-dm-candidates/nordisk-dm-candidates.component';
import { NordiskKredsOverViewComponent } from './components/nordisk/nordisk-kreds-overview/nordisk-kreds-overview.component';
import { NordiskLiveResultaterComponent } from './components/nordisk/nordisk-live-resultater/nordisk-live-resultater.component';
import { NordiskResultaterComponent } from './components/nordisk/nordisk-resultater/nordisk-resultater.component';
import { NoseWorkDmCandidatesComponent } from './components/nosework/nosework-dm-candidates/nosework-dm-candidates.component';
import { NoseWorkKredsOverViewComponent } from  './components/nosework/nosework-kreds-overview/nosework-kreds-overview.component';
import { NoseWorkLiveResultaterComponent } from './components/nosework/nosework-live-resultater/nosework-live-resultater.component';
import { NoseWorkResultaterComponent } from './components/nosework/nosework-resultater/nosework-resultater.component';
import { PageNotFoundComponent } from './components/pageNotFound/PageNotFound.component';
import { RallyDmCandidatesComponent } from './components/rally/rally-dm-candidates/rally-dm-candidates.component';
import { RallyKredsOverViewComponent } from './components/rally/rally-kreds-overview/rally-kreds-overview.component';
import { RallyLiveResultaterComponent } from './components/rally/rally-live-resultater/rally-live-resultater.component';
import { RallyResultaterComponent } from './components/rally/rally-resultater/rally-resultater.component';
import { StamDataComponent } from './components/stam-data/stam-data.component';

const appRoutes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full' },
  { path: 'admin/dcHCalendar', component: AdminDcHCalendarComponent, canActivate: [AuthGuard] },
  { path: 'admin/frontPageNews', component: AdminFrontPageNewsComponent, canActivate: [AuthGuard] },
  { path: 'admin/importKonkurrencer', component: AdminImportKonkurrencerComponent, canActivate: [AuthGuard] },
  { path: 'admin/konkurrencer', component: AdminKonkurrencerComponent, canActivate: [AuthGuard] },
  { path: 'admin/liveKonkurrencer', component: AdminLiveKonkurrencerComponent, canActivate: [AuthGuard] },
  { path: 'admin/login', component: LoginComponent },
  { path: 'admin/logs', component: AdminLogsComponent, canActivate: [AuthGuard] },
  { path: 'admin/stamData', component: AdminStamDataComponent, canActivate: [AuthGuard] },
  { path: 'agilityDmKandidater', component: AgilityDmCandidatesComponent, title: 'DcH DM kandidater' },
  { path: 'agilityLiveResultater', component: AgilityLiveResultaterComponent, title: 'DcH live konkurrence' },
  { path: 'agilityLiveResultater/:konkId', component: AgilityLiveResultaterComponent, title: 'DcH live konkurrence' },
  { path: 'agilityResultater', component: AgilityResultaterComponent, title: 'DcH konkurrence resultater' },
  { path: 'agilityResultater/:konkId', component: AgilityResultaterComponent, title: 'DcH konkurrence resultater' },
  { path: 'aretsAgilityHund', component: AgilityDogOfTheYearComponent, title: 'DcH Årets hund' },
  { path: 'beregnerprogrammer', component: BeregnerProgrammerComponent, title: 'DcH beregnerprogrammer' },
  { path: 'brugsIgpFhDmKandidater', component: BrugsIgpFhDmCandidatesComponent, title: 'DcH DM kandidater' },
  { path: 'brugsIpg3DmKandidater', component: BrugsIpg3DmCandidatesComponent, title: 'DcH DM kandidater' },
  { path: 'brugsLiveResultater', component: BrugsLiveResultaterComponent, title: 'DcH live konkurrence' },
  { path: 'brugsLiveResultater/:konkId', component: BrugsLiveResultaterComponent, title: 'DcH live konkurrence' },
  { path: 'brugsResultater', component: BrugsResultaterComponent, title: 'DcH konkurrence resultater' },
  { path: 'brugsResultater/:konkId', component: BrugsResultaterComponent, title: 'DcH konkurrence resultater' },
  { path: 'ekvipageResultater/:profileId/:dogProfileId', component: EkvipageResultsComponent },
  { path: 'ekvipageResultater/:profileId/:dogProfileId/:konkurrenceType', component: EkvipageResultsComponent },
  { path: 'eturKandidater', component: LydighedETurCandidatesComponent, title: 'DcH E Tur kandidater' },
  { path: 'eturResultater', component: LydighedETurResultsComponent, title: 'DcH E tur. resultater' },
  { path: 'hoopersDmKandidater', component: HoopersDmCandidatesComponent, title: 'DcH DM kandidater' },
  { path: 'hoopersLiveResultater', component: HoopersLiveResultaterComponent, title: 'DcH live konkurrence' },
  { path: 'hoopersLiveResultater/:konkId', component: HoopersLiveResultaterComponent, title: 'DcH live konkurrence' },
  { path: 'hoopersResultater', component: HoopersResultaterComponent, title: 'DcH konkurrence resultater' },
  { path: 'hoopersResultater/:konkId', component: HoopersResultaterComponent, title: 'DcH konkurrence resultater' },
  { path: 'kredsoversigtAgility', component: AgilityKredsOverViewComponent, title: 'DcH kredsoversigt' },
  { path: 'kredsoversigtBrugs', component: BrugsKredsOverViewComponent, title: 'DcH kredsoversigt' },
  { path: 'kredsoversigtHoopers', component: HoopersKredsOverViewComponent, title: 'DcH kredsoversigt' },
  { path: 'kredsoversigtLydighed', component: LydighedKredsOverViewComponent, title: 'DcH kredsoversigt' },
  { path: 'kredsoversigtNordisk', component: NordiskKredsOverViewComponent, title: 'DcH kredsoversigt' },
  { path: 'kredsoversigtNoseWork', component: NoseWorkKredsOverViewComponent, title: 'DcH kredsoversigt' },
  { path: 'kredsoversigtRally', component: RallyKredsOverViewComponent, title: 'DcH kredsoversigt' },
  { path: 'lydighedDmKandidater', component: LydighedDmCandidatesComponent, title: 'DcH DM kandidater' },
  { path: 'lydighedLiveResultater', component: LydighedLiveResultaterComponent, title: 'DcH live konkurrence' },
  { path: 'lydighedLiveResultater/:konkId', component: LydighedLiveResultaterComponent, title: 'DcH live konkurrence' },
  { path: 'lydighedOprykkere', component: LydighedOprykkereComponent, title: 'DcH program oprykkere' },
  { path: 'lydighedResultater', component: LydighedResultaterComponent, title: 'DcH konkurrence resultater' },
  { path: 'lydighedResultater/:konkId', component: LydighedResultaterComponent, title: 'DcH konkurrence resultater' },
  { path: 'nordiskDmKandidater', component: NordiskDmCandidatesComponent, title: 'DcH DM kandidater' },
  { path: 'nordiskLiveResultater', component: NordiskLiveResultaterComponent, title: 'DcH live konkurrence' },
  { path: 'nordiskLiveResultater/:konkId', component: NordiskLiveResultaterComponent, title: 'DcH live konkurrence' },
  { path: 'nordiskResultater', component: NordiskResultaterComponent, title: 'DcH konkurrence resultater' },
  { path: 'nordiskResultater/:konkId', component: NordiskResultaterComponent, title: 'DcH konkurrence resultater' },
  { path: 'noseWorkDmKandidater', component: NoseWorkDmCandidatesComponent, title: 'DcH DM kandidater' },
  { path: 'noseWorkLiveResultater', component: NoseWorkLiveResultaterComponent, title: 'DcH live konkurrence' },
  { path: 'noseWorkLiveResultater/:konkId', component: NoseWorkLiveResultaterComponent, title: 'DcH live konkurrence' },
  { path: 'noseWorkResultater', component: NoseWorkResultaterComponent, title: 'DcH konkurrence resultater' },
  { path: 'noseWorkResultater/:konkId', component: NoseWorkResultaterComponent, title: 'DcH konkurrence resultater' },
  { path: 'rallyDmKandidater', component: RallyDmCandidatesComponent, title: 'DcH DM kandidater' },
  { path: 'rallyLiveResultater', component: RallyLiveResultaterComponent, title: 'DcH live konkurrence' },
  { path: 'rallyLiveResultater/:konkId', component: RallyLiveResultaterComponent, title: 'DcH live konkurrence' },
  { path: 'rallyResultater', component: RallyResultaterComponent, title: 'DcH konkurrence resultater' },
  { path: 'rallyResultater/:konkId', component: RallyResultaterComponent, title: 'DcH konkurrence resultater' },
  { path: 'stamData', component: StamDataComponent, title: 'DcH Stamdata' },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, { useHash: true })
  ],
  exports: [
    RouterModule
  ]
})

export class AppRoutingModule { }
