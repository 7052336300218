import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, QueryList, TemplateRef, ViewChildren, ViewContainerRef } from '@angular/core';
import { AppMaterialModule } from '../../app-material.module';
import { DataColumn, DcHDataTable } from '../../models/dch-data-table';
import { DcHDurationPipe } from '../../pipes/dch-duration.pipe';
import { DcHFullDatePipe } from '../../pipes/dch-full-date.pipe';
import { DcHKonkurrenceArtText } from '../../pipes/dch-konkurrence-art-text.pipe';
import { DcHKonkurrenceTypeText } from '../../pipes/dch-konkurrence-type-text.pipe';
import { DcHPlacerText } from '../../pipes/dch-placer-text.pipe';
import { DcHTilmeldStatusComponent } from '../dch-tilmeld-status/dch-tilmeld-status.component';
import { EkvipageLinkComponent } from '../ekvipage-link/ekvipage-link.component';

@Component({
  selector: 'app-dch-data-table',
  templateUrl: './dch-data-table.component.html',
  styleUrls: ['./dch-data-table.css', './dch-data-table-sticky.css', './dch-data-table-primary.css', './dch-data-table-secondary.css'],
  standalone: true,
  imports: [CommonModule, AppMaterialModule, EkvipageLinkComponent, DcHTilmeldStatusComponent, DcHPlacerText, DcHFullDatePipe, DcHKonkurrenceArtText, DcHKonkurrenceTypeText, DcHDurationPipe]
})
export class DcHDataTableComponent implements OnInit {

  updating = false;
  selectedIndex = -1;
  prevSelectedIndex = -1;

  private _dataSource: any[] | undefined;

  @ViewChildren('rowMenuContainerRef', { read: ViewContainerRef })
  rowMenuContainerRefs: QueryList<ViewContainerRef>;

  @Output()
  selectedChanged = new EventEmitter<any>();

  @Input({ required: true })
  dataTable: DcHDataTable;

  @Input()
  dataRowDetails: TemplateRef<any>;

  @Input()
  rowMenuItems: TemplateRef<any>;

  @Input()
  editRowData: TemplateRef<any>;
  
  @Input({ required: true })
  get dataSource(): any[] | undefined {
    return this._dataSource;
  }
  set dataSource(value: any[] | undefined) {
    this._dataSource = value;
    if (this._dataSource != undefined && this.dataTable.allowRowSelection) {
      this.prevSelectedIndex = -1;
      this.rowSelectionHandler(0, this._dataSource[0])
    }

    this.triggerAnimation();
  }

  constructor() {
  }

  ngOnInit(): void {

  }

  rowSelectionHandler(index: number, args: any): void{
    this.selectedIndex = index;
    
    if (this.prevSelectedIndex != this.selectedIndex) {
      this.selectedChanged.next(args);
      this.prevSelectedIndex = this.selectedIndex;
    }
  }

  rowMenuClicked(arg: any, index: number, rowData: any): void {

    const componentRefIndex = this.rowMenuContainerRefs.get(index);
    if (componentRefIndex != undefined) {
      componentRefIndex.clear();
      const context = { $implicit: rowData };
      componentRefIndex.createEmbeddedView(this.rowMenuItems, context);
    }
  }

  rowCommandClicked(index: number): void {
    if (this.dataSource != undefined) {
      for (let i = 0; i < this.dataSource.length; i++) {
        if (i !== index) {
          this.dataSource[i].isExpand = false;
        }
      }
    }
  }

  detailsClicked(index: number): void {
    if (this.dataTable.autoCloseDetails && this.dataSource != undefined) {
      for (let i = 0; i < this.dataSource.length; i++) {
        if (i !== index) {
          this.dataSource[i].isExpand = false;
        }
      }
    }
  }

  getValue(rowData: any, column: DataColumn): any {

    if (column.field == undefined || column.field.length < 1) {
      return rowData;
    }

    let fields = column.field.split('.');

    for (let i = 0; i < fields.length; i++) {
      let arrayIndex = fields[i].substring(fields[i].indexOf("[") + 1, fields[i].lastIndexOf("]"));
      
      if (arrayIndex.length > 0 && rowData != undefined) {
        let fieldName = fields[i].substring(0, fields[i].lastIndexOf("["));
        rowData = rowData[fieldName][+arrayIndex];
      }
      else if (rowData != undefined) {
        rowData = rowData[fields[i]];
      }
    }

    if (column.fnFormatter != undefined) {
      return column.fnFormatter(rowData);
    }

    return rowData;
  }

  private triggerAnimation(): void {
    this.updating = true;
    setTimeout(() => { this.updating = false }, 350);
  }
}
