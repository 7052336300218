import { MenuItem, MenuSection } from '../models/menu-item';
import { UserInfo } from '../models/user-info';

export class buildNavigationMenuHelpers {

  getNavigationMenu(userInfo: UserInfo | null): MenuSection[] {

    const menuSections: MenuSection[] = [
      this.getAgilityNavigationMenu(),
      this.getBrugsNavigationMenu(),
      this.getLydNavigationMenu(),
      this.getHoopersNavigationMenu(),
      this.getNordiskNavigationMenu(),
      this.getNoseWorkNavigationMenu(),
      this.getRallyNavigationMenu(),
      this.getDiverseNavigationMenu()
    ];

    if (userInfo?.hasRole('admin')) {

      menuSections.push(this.getAdminNavigationMenu(userInfo));
    }

    return menuSections;
  }

  private getAgilityNavigationMenu(): MenuSection {
    const menuSection = new MenuSection('Agility');

    menuSection.menuItems = [
      new MenuItem('Konkurrence resultater', '/agilityResultater'),
      new MenuItem('Resultat oversigt', '/kredsoversigtAgility'),
      new MenuItem('Årets hund', '/aretsAgilityHund'),
      new MenuItem('Live konkurrence resultater', '/agilityLiveResultater'),
      new MenuItem('DM Kandidater', '/agilityDmKandidater')
    ];

    return menuSection;
  }

  private getBrugsNavigationMenu(): MenuSection {
    const menuSection = new MenuSection('Brugsprøver');

    menuSection.menuItems = [
      new MenuItem('Konkurrence resultater', '/brugsResultater'),
      new MenuItem('Resultat oversigt', '/kredsoversigtBrugs'),
      new MenuItem('Live konkurrence resultater', '/brugsLiveResultater'),
      new MenuItem('IGP3 DM Kandidater', '/brugsIpg3DmKandidater'),
      new MenuItem('IGP-FH DM Kandidater', '/brugsIgpFhDmKandidater')
    ];

    return menuSection;
  }

  private getLydNavigationMenu(): MenuSection {
    const menuSection = new MenuSection('DcH-Program');

    menuSection.menuItems = [
      new MenuItem('Konkurrence resultater', '/lydighedResultater'),
      new MenuItem('Resultat oversigt', '/kredsoversigtLydighed'),
      new MenuItem('Live konkurrence resultater', '/lydighedLiveResultater'),
      new MenuItem('E tur kandidater', '/eturKandidater'),
      new MenuItem('E tur resultater', '/eturResultater'),
      new MenuItem('Oprykkere', '/lydighedOprykkere'),
      new MenuItem('DM Kandidater', '/lydighedDmKandidater')
    ];

    return menuSection;
  }

  private getHoopersNavigationMenu(): MenuSection {
    const menuSection = new MenuSection('Hoopers');

    menuSection.menuItems = [
      new MenuItem('Konkurrence resultater', '/hoopersResultater'),
      new MenuItem('Resultat oversigt', '/kredsoversigtHoopers'),
      new MenuItem('Live konkurrence resultater', '/hoopersLiveResultater'),
      new MenuItem('DM Kandidater', '/hoopersDmKandidater')
    ];

    return menuSection;
  }

  private getNordiskNavigationMenu(): MenuSection {
    const menuSection = new MenuSection('Nordisk');

    menuSection.menuItems = [
      new MenuItem('Konkurrence resultater', '/nordiskResultater'),
      new MenuItem('Resultat oversigt', '/kredsoversigtNordisk'),
      new MenuItem('Live konkurrence resultater', '/nordiskLiveResultater'),
      new MenuItem('DM Kandidater', '/nordiskDmKandidater')
    ];

    return menuSection;
  }

  private getNoseWorkNavigationMenu(): MenuSection {
    const menuSection = new MenuSection('Nose Work');

    menuSection.menuItems = [
      new MenuItem('Konkurrence resultater', '/noseWorkResultater'),
      new MenuItem('Resultat oversigt', '/kredsoversigtNoseWork'),
      new MenuItem('Live konkurrence resultater', '/noseWorkLiveResultater'),
      new MenuItem('DM Kandidater', '/noseWorkDmKandidater')
    ];

    return menuSection;
  }

  private getRallyNavigationMenu(): MenuSection {
    const menuSection = new MenuSection('Rally');

    menuSection.menuItems = [
      new MenuItem('Konkurrence resultater', '/rallyResultater'),
      new MenuItem('Resultat oversigt', '/kredsoversigtRally'),
      new MenuItem('Live konkurrence resultater', '/rallyLiveResultater'),
      new MenuItem('DM Kandidater', '/rallyDmKandidater')
    ];

    return menuSection;
  }

  private getDiverseNavigationMenu(): MenuSection {
    const menuSection = new MenuSection('Diverse');

    menuSection.menuItems = [
      new MenuItem('Stamdata', '/stamData'),
      new MenuItem('Beregnerprogrammer', '/beregnerprogrammer')
    ];

    return menuSection;
  }

  private getAdminNavigationMenu(userInfo: UserInfo): MenuSection {
    const menuSection = new MenuSection('Admin');

    menuSection.menuItems = [
      new MenuItem('Admin konkurrencer', '/admin/konkurrencer'),
      new MenuItem('Indlæs konkurrencer', '/admin/importKonkurrencer'),
      new MenuItem('Live konkurrencer', '/admin/liveKonkurrencer'),
      new MenuItem('Forside nyheder', '/admin/frontPageNews'),
      new MenuItem('DcH kalendar', '/admin/dcHCalendar'),
      new MenuItem('Admin stamdata', '/admin/stamData')
    ];

    if (userInfo.hasRole('developer')) {
      menuSection.menuItems.push(new MenuItem('Logs', '/admin/logs'));
    }

    menuSection.menuItems.push(new MenuItem('Logout ' + userInfo.username, '/'));
    return menuSection;
  }
}


